import axios from 'axios'
import React, { useContext, useRef } from 'react'
import { baseUrl, headers } from '../config/config';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from './Loader';
import { Context } from '../Context/Context';
import { txt } from './text';
import backBtn from "./themeimg/assets/back-btn.png";
import { SendGuiDataEvents } from '../CommonScript';
function Unsub() {
    const { pageLoader , handlePageLoader} = useContext(Context)
    const navigate = useNavigate()
    const lang = localStorage.getItem("appLang")

    const unsubReqeustHandler = () => {
      let guiEvents={};
      guiEvents["page"]="unsub"
      guiEvents["event"]="unsub_request"
      SendGuiDataEvents(guiEvents)     
        handlePageLoader(true)
        axios({
            url: baseUrl + '/unsubscribe',
            method: 'post',
            data: {
                "msisdn": localStorage.getItem('msisdn'),               
            },
            headers: headers
        }).then((resp) => {
          handlePageLoader(false);
          let guiResEvents={};
          guiResEvents["page"]="unsub"
          guiResEvents["event"]="unsub_response"
          guiResEvents["status"]=resp?.data?.subStatus
          SendGuiDataEvents(guiResEvents)
            if (resp.data.status === 'failed') {
                navigate("/unsubfail")

            } else if (resp.data.status === 'success') {
                console.log("api successful")
                navigate("/unsubsuccess")
            }
        }).catch((error)=>
        {
          let guiEventsError = {};
          guiEventsError["page"] = "welcome";
          guiEventsError["event"] = "checksub_response_error";
          guiEventsError["error_status"] = error.status || "unknown";
          guiEventsError["error_message"] = error.statusText || "Error occurred during pass token ";
          SendGuiDataEvents(guiEventsError);
        }).finally(() => {
          // This block will always execute, regardless of the promise outcome
          handlePageLoader(false);
        });
  }

  const gotoHome = () => {
    navigate("/home")
  }
  const showConfirmPage = ()=>{
    unsubReqeustHandler();
  }


    return (
        <div className='max-w-[500px] mx-auto  h-[100vh]'>
            {pageLoader && <LoaderComponent />}
            <div className='upper-yellow-bg backdrop-blur-sm pt-4 bg-[#9D328D]'>
                  <div className='back-arrow back-btn-quiz '  onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                         <img src={backBtn} alt="back"/>
                        </div>
                      </div>
                    <h5 className='has-text-black has-text-centered mt-2'>{txt.unsub[lang]}</h5>
                  </div>
        <div className='bg-unSub w-[90%] mx-auto mt-8 h-[18rem] bg-cover bg-center bg-no-repeat '></div>
        <div className='my-2 font-bold mx-auto w-[80%] text-lg text-center pt-10'> {txt.unsubtxt[lang]}</div>
       <div className='pt-10'> <div className='button is-unsub mx-auto' onClick={showConfirmPage}><button>{txt.cnfrmunsub[lang]}</button></div></div>
      </div>
    )
}

export default Unsub