import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoaderComponent from './Loader';
import { Context } from "../Context/Context";
import { txt } from "./text";
import backBtn from "./themeimg/assets/back-btn.png";
import { baseUrl, headers } from "../config/config";
import { SendGuiDataEvents } from "../CommonScript";
function Gallery() {
  const navigate = useNavigate();
  const lang = localStorage.getItem("appLang")
    ? localStorage.getItem("appLang")
    : "en";
  const [imgNumber, setImgNumber] = useState(0)
  const [data, setData] = useState(null);
  const [mainImg, setMainImg] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { pageLoader, handlePageLoader, changeErrorState } = useContext(Context)
  const initialImageIndex = parseInt(localStorage.getItem("lastViewedImageIndex"), 10) || 0;
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);

  useEffect(() => {
    let guiEvents={};
      guiEvents["page"]="gallery"
      guiEvents["event"]="photogalleryJson_request"
      SendGuiDataEvents(guiEvents)   
    const url = baseUrl + "photogalleryJson";
    handlePageLoader(true)
    fetch(url,
      {
        headers: headers
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data, "data--");
        let guiEvents={};
      guiEvents["page"]="gallery"
      guiEvents["event"]="photogalleryJson_response"
       guiEvents["status"]=data?.status;
      SendGuiDataEvents(guiEvents)   
        const lastViewedImageIndex = localStorage.getItem("lastViewedImageIndex");
        setMainImg(
          data?.wallpapers[lastViewedImageIndex]?.url || data?.wallpapers[0]?.url
        );
      })
      .catch((error) => {
        console.log("Fetch error:", error);
        let guiEventsError = {};
        guiEventsError["page"] = "gallery";
        guiEventsError["event"] = "photogalleryJson_response_error";
        guiEventsError["error_status"] = error.response?.status || "unknown";
        guiEventsError["error_message"] = error.message || "Error occurred during the API call";
        SendGuiDataEvents(guiEventsError);
      });
  }, []);



  useEffect(() => {
    if (imgNumber >= data?.wallpapers.length) {
      handlePageLoader(false);
    }
  }, [imgNumber, data]);

  const handleImageClick = (url, index) => {
    if (index !== currentImageIndex) {
      setIsLoading(true);
    }
    setMainImg(url);
    setCurrentImageIndex(index);
    localStorage.setItem("lastViewedImageIndex", index);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleImageLoad = () => {
    setImgNumber((value) => value + 1);
    setIsLoading(false);
  };

  const handlePrevImage = () => {
    setIsLoading(true)
    setCurrentImageIndex(prevIndex => {
      const storedIndex = parseInt(localStorage.getItem("lastViewedImageIndex"), 10);
      const newIndex = storedIndex > 0 ? storedIndex - 1 : prevIndex;
      localStorage.setItem("lastViewedImageIndex", newIndex);
      return newIndex;
    });
  };

  const handleNextImage = () => {
    setIsLoading(true)
    setCurrentImageIndex(prevIndex => {
      // const newIndex = prevIndex < data?.imageUrls.length - 1 ? prevIndex + 1 : prevIndex;
      const storedIndex = parseInt(localStorage.getItem("lastViewedImageIndex"), 10);
      const newIndex =
        storedIndex < data?.wallpapers.length - 1 ? storedIndex + 1 : prevIndex;
      localStorage.setItem("lastViewedImageIndex", newIndex);
      return newIndex;
    });
  };

  useEffect(() => {
    if (data && data.wallpapers.length > 0) {
      setMainImg(data.wallpapers[currentImageIndex].url);
    }
  }, [currentImageIndex, data]);

  const gotoHome = () => {
    navigate("/home")
  }
  console.log("currentindex", currentImageIndex)


  return (
    <div className="h-screen max-w-[500px] mx-auto">
      <div className="h-20 flex bg-[#9D328D]">

        <div className="pt-6">
          <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
            <div className='back-arrow-button back-btn-align'>
              {/*                        <p  className="arrow left "></p> */}
              <img src={backBtn} alt="back" />
            </div>
          </div></div>
        <div className="my-auto mx-auto font-bold relative font-playfair right-4 text-[#0C0C0C] text-2xl sm:text-3xl  ">
          {txt.gallery[lang]}
        </div>
      </div>
      <div className="ml-2 mr-2 pt-4  text-black ">
        {pageLoader && <LoaderComponent />}
        <div className="w-[90%] mx-auto h-60 relative" style={{ display: "none" }}>
          {isLoading && <LoaderComponent />}
          <div className="bg-[#9D328D] h-8 w-8 rounded-full absolute left-4 top-1/2 transform -translate-y-1/2">
            <div
              className={`absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 text-xl text-white ${currentImageIndex === 0 ? "pointer-events-none opacity-50" : ""
                }`}
              onClick={handlePrevImage}
            >&lt;</div></div>
          <img
            className=" border-2 border-white rounded-lg h-full"
            src={mainImg}
            alt=""
            onLoad={handleImageLoad}
          />
          <div className="bg-[#9D328D] h-8 w-8 rounded-full absolute right-4 top-1/2 transform -translate-y-1/2">
            <div
              className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 text-xl text-white ${currentImageIndex === data?.wallpapers?.length - 1
                  ? "pointer-events-none opacity-50"
                  : ""
                }`}
              onClick={handleNextImage}
            >
              &gt;
            </div>
          </div>
        </div>
        <div className="" style={{ display: 'flex', justifyContent: 'center', padding: "12px" }}>
          <div className="grid grid-cols-2 gap-x-4 gap-y-4">
            {data?.wallpapers?.map((data, index) => (
              <div className="relative w-[11rem] h-[11rem]" key={data.url}>
                <img
                  onLoad={() => setImgNumber(value => value + 1)}
                  className="absolute border-2 border-white rounded-lg w-full h-full"
                  src={data.url}
                  alt={data?.name}
                  onClick={() => handleImageClick(data.url, index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )

}

export default Gallery;
