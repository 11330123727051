import React, { useEffect } from "react";
import "../components/style/firstLandingPage.css";
import waterfall from "../../src/waterfall.jpg";
import { useNavigate ,useLocation} from "react-router-dom";
import queryString from 'query-string';
import headerImage from './themeimg/assets/logo_new.png'

function DreamTravel() {
  const navigate = useNavigate();
  const location = useLocation();
 
  useEffect(() => {
    let params = queryString.parse(location.hash)
    console.log("params", params)
    console.log("params.uid", params["/?uid"])
    const uid = params["/?uid"];
    if(uid)
    {
      localStorage.setItem('uid',uid);
    }
    const timer = setTimeout(() => {
      navigate("/welcome"); // Redirect to the other route after 3 seconds
    }, 2000);
    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, [navigate]);
  return (
    <div className="container h-screen">
     
      <div className="magnify-animation">
        <img src={waterfall} alt="Waterfall" className="background-image mainImg" />
        <img src={headerImage} alt="service logo" className="logoImg"/>
      </div>
    </div>
  );
}

export default DreamTravel;
