import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {useNavigate } from 'react-router-dom';
import { baseUrl, headers } from '../config/config';
import Loader from './Loader';
import './style/scoreboard.css';
import { txt } from './text';
import backBtn from "./themeimg/assets/back-btn.png";
import { SendGuiDataEvents } from '../CommonScript';
export default function Scoreboard({ }) {
    const lang= localStorage.getItem("appLang");
    const [sbData, setSbData] = useState([])
    const [loader, setLoader] = useState(true)
    const [selected,setSelected]=useState("weekly");
    const [yourScore,setYourScore]=useState("");
    const [userRank,setUserRank]=useState("");
    useEffect(() => {
        let guiEvents={};
        guiEvents["page"]="scoreboard"
        guiEvents["event"]="page_view"
        SendGuiDataEvents(guiEvents)
        scoreBoard()
    }, []);
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const scoreBoard = async () => {
        try{
        let guiEvents={};
        guiEvents["page"]="scoreboard"
        guiEvents["event"]="scoreboard_request"
        SendGuiDataEvents(guiEvents)
        setLoader(true);
        console.log("selcted is:::",selected)
        let date;
        let isMonthly;      
        if (selected === 'weekly') {
            date = formatDate(new Date()); 
            isMonthly = false;
        } else if (selected === 'monthly') {
            date = formatDate(new Date()); 
            isMonthly = true;
        } else if (selected === 'lastWeek') {
            date = getStartDateOfPreviousWeek(new Date());
            isMonthly = false;
        } else if (selected === 'lastMonth') {
            date = getStartDateOfPreviousMonth(new Date());
            isMonthly = true;
        }

        const response = await axios({
            method: 'post',
            url: baseUrl + 'topScore',
            data: {
                isMonthly,
                selected_date:date,
                userid: localStorage.getItem('msisdn')
            },
            headers: headers,
        });
        console.log("api repsponse is:::",response?.data);
        let guiResEvents={};
          guiResEvents["page"]="scoreboard"
          guiResEvents["event"]="scoreboard_response"
          guiResEvents["status"]=response?.data?.subStatus
          SendGuiDataEvents(guiResEvents)
        setUserRank(response?.data?.userRank)
        setYourScore(response?.data?.scoreBoard)
        setSbData(response?.data?.scoreBoards);
        setLoader(false);
        }
        catch (error) {
            console.error("Error occurred during API call:", error);  
            let guiEventsError = {};
            guiEventsError["page"] = "scoreboard";
            guiEventsError["event"] = "scoreboard_response_error";
            guiEventsError["error_status"] = error.response?.status || "unknown";
            guiEventsError["error_message"] = error.message || "Error occurred during the API call";
            SendGuiDataEvents(guiEventsError);
        
          } finally {
            setLoader(false);
          }
    };

    const getStartDateOfPreviousMonth = (date) => {
        const previousMonth = new Date(date);
        previousMonth.setMonth(previousMonth.getMonth() - 1);
        return formatDate(previousMonth);
    };
    
    const getStartDateOfPreviousWeek = (date) => {
        const previousWeek = new Date(date);
        previousWeek.setDate(previousWeek.getDate() - 7);
        return formatDate(previousWeek);
    };
    const navigate = useNavigate()
    const gotoHome = () => {
        navigate('/home');
    }
  
    function changeToStar(num) {
        const temp = num?.split('')
        for (let i = 0; i < temp?.length - 3; i++) {
            temp[i] = "*"
        }
        let ans = temp?.join('')
        return ans
    }

    useEffect(() => {
        scoreBoard()
    }, [selected])

    return (
        <>
            {(loader ? <Loader /> :
                <div className='scoreboard-container '>
                    <div className='container scoreboard-bg'>
                        <div className='section-home'>
                            <div className='container-nav-bar'>
                                <div className='nav-bar border-2'>
                                    <div className='back-arrow'>
                                        <div className='back-arrow-button' onClick={() => gotoHome()}>
                                            <img src={backBtn} alt='back'/>
                                        </div>
                                    </div>
                                    <div className='header-hosted-page'>{txt.leaderboard[lang]}</div>
                                </div>
                            </div>
                          
                            <div className='leaderboard-toggle-button'>
                               <button
                                    className={selected === 'weekly' ? 'selected' : ''}
                                    onClick={() => 
                                        {
                                            setSelected('weekly');
                                        }}
                                >
                                    Weekly
                                </button>
                                <button
                                    className={selected === 'monthly' ? 'selected' : ''}
                                    onClick={() => 
                                        {
                                            setSelected('monthly')
                                        }}
                                >
                                    Monthly
                                </button>
                           </div>

                            <div className='hosted-page-body m-2'>
                                <div className='flex padding-2 header-text'>
                                    <div className='p-2'>RANK</div>
                                    <div className='p-2'>SCORE</div>
                                    <div className='p-2'>MSISDN</div>
                                </div>
                                {yourScore &&                                 
                                        <div className='yourScore grid padding-2 '>
                                            <div className=' content-text '>{userRank}</div>
                                            <div className=' content-text content-center font-bold'>{yourScore?.score}</div>
                                            <div className=' content-text '>{changeToStar(yourScore?.userId)}</div>
                                        </div>
                                }
                                {sbData?.map((data, index) => {
                                    return (<>
                                        <div key={data?.id} className='grid padding-2 z-0'>
                                            <div className=' content-text '>{index + 1}</div>
                                            <div className=' content-text content-center font-bold'>{data?.score}</div>
                                            <div className=' content-text '>{changeToStar(data?.userId)}</div>
                                        </div>
                                    </>)
                                })}              
                                </div>

                               <div className='center-button'>
                                {(selected==='weekly' || selected==='lastWeek' )?                                
                                   <button
                                         className={`btn1 ${selected==='lastWeek'?'selected':''}`}
                                         onClick={() =>
                                             {
                                                setSelected('lastWeek') 
                                             }
                                         }
                                         >
                                         Last Week Results
                                     </button>:
                                      <button
                                      className={`btn1 ${selected==='lastMonth'?'selected':''}`}
                                      onClick={() =>
                                          {
                                             setSelected('lastMonth') 
                                          }
                                      }
                                      >
                                      Last Month Results
                                  </button>
                                }
                                </div>
                        </div>
                    </div>
                </div>)}
        </>
    )
}
