import React, { useContext,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context/Context";
import { txt } from "./text";
import GeneralModal from "./GeneralModal";
import backBtn from "./themeimg/assets/back-btn.png";
import { SendGuiDataEvents } from "../CommonScript";
function UnlockQuestions() {
  const navigate = useNavigate();
  const { toggleSubModal,showUnlockModal } = useContext(Context);
  const lang = localStorage.getItem("appLang");
  useEffect(()=>{
    let guiEvents={};
    guiEvents["page"]="unlockQuestion"
    guiEvents["event"]="page_view"
    SendGuiDataEvents(guiEvents)
  },[]);
  const gotoHome = () => {
    toggleSubModal()
    navigate("/home")
  };
  if(showUnlockModal) return <GeneralModal/>;
  return (
    <div className=" h-screen bg-[#F3F3F3] max-w-[500px] mx-auto">
      <div className=" h-20 flex bg-[#FFCB00]">
        <div className="pt-6 ">
          <div
            className="back-arrow back-btn-quiz"           
            onClick={() => gotoHome()}
          >
            <div className="back-arrow-button back-btn-align">
              <img src={backBtn} alt="back"/>
            </div>
          </div>
        </div>
          <div className="my-auto mx-auto font-bold relative font-playfair right-4 text-[#0C0C0C] text-3xl  ">
            {txt.unlockHeading[lang]}
            </div>
      </div>

      <div className="mt-2 pt-4 items-center">
        <div className="border-2 mx-auto border-[#FFCB00] rounded-xl w-[80%] h-48 shadow-xl bg-white flex " style={{flexDirection:'column',justifyContent:'space-around'}}>
          <div className="  pt-4">
            <div className="text-center text-xl mx-auto">
              {txt.unlockquestion[lang]}
            </div>
          </div>
          <div className=" w-full mt-[1.35rem]">
            <div
              onClick={() => toggleSubModal(true)}
              className=" text-white bg-[#9D328D] w-[50%] rounded-full px-4 py-[0.25rem] mx-auto text-center text-normal cursor-pointer"
            >
              {" "}
              {txt.unlockat5mad[lang]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnlockQuestions;
