let headers = {

    'Servicefor': 'mtn_uganda',
    'Username':'web',
    'Language': localStorage.getItem("appLang") ? localStorage.getItem("appLang") : 'en'
}


let data = {
    "calling_code": "Ghana",
    "msisdn": localStorage.getItem("msisdn")

}


// const baseUrl = 'https://sandboxapisv1.bngrenew.com/ongoing_inwimo/apn/'
const baseUrl="https://digitalportal.bngrenew.com/mtnuga/"



export {headers , data , baseUrl}