import React, { useEffect, useState } from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { baseUrl, data, headers } from '../config/config';
import LoaderComponent from './Loader';
import './style/contest.css';
import iconSet from './themeimg/assets/steps.png';
import headerImage from './themeimg/assets/logo_new.png'
import axios from 'axios';
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';
import backBtn from "./themeimg/assets/back-btn.png";
export default function ContestWelcome(props) {
  const resource = props.configData.contest_rules;
  console.log("resources ----> ", resource)
  const navigate = useNavigate();
  const [nextQue,setNextQue] = useState();
  const [availableLevel,setAvailableLevel] =  useState();
  const [stateSendObj,setStateSendObj] =  useState({ nextQue: "", availableLevel: "" });
  const [pageLoader,setPageLoader] = useState(true)
  const lang = localStorage.getItem("appLang")
  const [dataRecieved,setDataRecieved] = useState(false)

  useEffect(()=>{
    let guiEvents={};
    guiEvents["page"]="contest"
    guiEvents["event"]="page_view"
    SendGuiDataEvents(guiEvents)
  },[])

  useEffect(()=>{
    getNextQuestionApi()
  },[])

  const getNextQuestionApi = async () => {
    try{
    let guiEvents={};
    guiEvents["page"]="contest"
    guiEvents["event"]="nextQuestion_request"
    SendGuiDataEvents(guiEvents)
    const response = await axios({
        method: 'post',
        url: baseUrl+'getNextQuestion',
        data: {...data ,"msisdn": localStorage.getItem("msisdn")},
        headers: headers,
    })
    let guiResEvents={};
    guiResEvents["page"]="contest"
    guiResEvents["event"]="nextQuestion_response"
    guiResEvents["status"]=response?.data?.subStatus
    SendGuiDataEvents(guiResEvents);

    setNextQue(response.data);
    console.log("respone for getquestions",response.data)
    console.log("dataques",nextQue)
    setStateSendObj(prevState => ({ ...prevState, ['nextQue']:response.data}));
    console.log("data",stateSendObj)

    if (response.data.subStatus === "EndOfQuestion") {
      getAvailableLevelsForUnlockApi();
    }
    else if(response.data.status?.toUpperCase() === "FAILURE")
    {
      setPageLoader(false);
      window.open("/home","_self")
    }else{
      setPageLoader(false)
    }
  }catch(error)
  {
    let guiEventsError = {};
    guiEventsError["page"] = "contest";
    guiEventsError["event"] = "nextQuestion_response_error";
    guiEventsError["error_status"] = error.response?.status || "unknown";
    guiEventsError["error_message"] = error.message || "Error occurred during the API call";
    SendGuiDataEvents(guiEventsError);
  }
}
const getAvailableLevelsForUnlockApi = async () => {
  try{
    let guiEvents={};
    guiEvents["page"]="contest"
    guiEvents["event"]="getAvailableLevelsForUnlockApi_request"
    SendGuiDataEvents(guiEvents);
  const response = await axios({
      method: 'post',
      url: baseUrl+'getAvailableLevelsForUnlock',
      data: {...data , "msisdn": localStorage.getItem("msisdn")},
      headers: headers,
  })
  setAvailableLevel(response.data);
  console.log("response for levels",response.data);
  let guiResEvents={};
  guiResEvents["page"]="contest"
  guiResEvents["event"]="getAvailableLevelsForUnlockApi_response"
  guiResEvents["status"]=response?.data?.status
  SendGuiDataEvents(guiResEvents)
  setStateSendObj(prevState => ({ ...prevState, ['availableLevel']:response.data}));
  console.log("stateobject",stateSendObj)
  setPageLoader(false);
  if(response?.data?.count> 0)
  {
    navigate("/unlock")
  }
  else{
    navigate("/unlockmodal")
  }
}catch(error)
{
  let guiEventsError = {};
  guiEventsError["page"] = "contest";
  guiEventsError["event"] = "getAvailableLevelsForUnlockApi_response_error";
  guiEventsError["error_status"] = error.response?.status || "unknown";
  guiEventsError["error_message"] = error.message || "Error occurred during the API call";
  SendGuiDataEvents(guiEventsError);
}

}

const redirectToQuiz = () => {
  {stateSendObj && console.log("dataques",stateSendObj.nextQue)}
   if(stateSendObj){
    setDataRecieved(true)
   }
  if (stateSendObj?.nextQue !== "") {
    // navigate('/quiz', { state: stateSendObj });
  }
};

useEffect(() => {
    const timeoutId = setTimeout(() => {
      redirectToQuiz();
    }, 5000);

    return () => clearTimeout(timeoutId);
},[dataRecieved]);

  const gotoHome = () => {
    navigate('/home');
  }
  const openUrl = ()=>{
    navigate("/menuList/Destination")
  }
 
  return (
      <>
        <div>
          <div>
            <div className="container">
              <div className="background-theme">
                <div className='contest-container'>
                  <div className='upper-yellow-bg  bg-white flex items-center justify-center backdrop-blur-sm'>
                  <div className='back-arrow back-btn-quiz'style={{backgroundColor:"white"}} onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                            {/* <p  className="arrow left "></p> */}
                                <img src={backBtn} alt="back"/>
                        </div>
                      </div>
                    {/* <h5 className='has-text-black has-text-centered '>DREAM TRAVEL</h5> */}
                    <img className="w-28 relative mx-auto right-5 h-16" src={headerImage} />
                  </div>
                  {pageLoader && <LoaderComponent />}
                  <div className='bottom-white-bg bg-[#F8F8F8] bg-cover pt-16 bg-no-repeat'>
                    <div className={nextQue && nextQue.subStatus === 'EndOfQuestion'? 'card card-height ':'card'}>
                    
                      {  nextQue && nextQue.subStatus === 'EndOfQuestion' ? 
                      null :
                        <div className='card-container has-text-centered'>
                          <div className='card-head font-extrabold '>{resource && resource.header}</div>
                          <div className='card-center-div'>
                            <div className='pt-8'>{resource && resource.heading}</div>
                            <img className='mx-auto py-2' src={iconSet} alt='imag' style={{width:'12rem'}}/>               
                          </div>
                          <p className=' mb-6 font-thin text-black'>{txt.tensectxt[lang]}</p>
                          <p className='card-para font-bold'>{resource && resource.rules.step2}</p>
                          <div className='card-last-sec cursor-pointer text-black' onClick={openUrl}>{resource && resource.footer}</div>
                      </div>
                      }

                    </div>
                    <div className='contest-play-button'>
                      {console.log("data",stateSendObj.nextQue)}
                      {stateSendObj.nextQue !="" &&
                      <Link className="button play-btn shadow-2xl" to={"/quiz"} state={stateSendObj}>{ txt.playnow[lang]}</Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
