import React from "react";
import { useNavigate } from "react-router-dom";
import headerImage from './themeimg/assets/logo_new.png'
import modal from "./themeimg/assets/unlock.png";
import { txt } from "./text";
import backBtn from "./themeimg/assets/back-btn.png";

function UnlockModal () {
    const navigate=useNavigate();
    const lang = localStorage.getItem("appLang")

      const openHome =()=>{
       navigate("/home");
      }

      const showScores =()=>{
        navigate("/scoreboard")
      }

    return(
        <div className="container ">
        <div className="background-theme">   
          <div className='contest-container '>
        <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
                  <div className='back-arrow back-btn-quiz'style={{backgroundColor:"white"}} onClick={openHome}>
                        <div className='back-arrow-button back-btn-align'>
                             <img src={backBtn} alt="back"/>
                        </div>
                      </div>
                      <img className="w-40 relative right-6 mx-auto h-16" src={headerImage} />
                      </div>
                      <div className='bottom-white-bg bg-[#FAFAFA] bg-cover bg-no-repeat relative pt-12'>
                        <div className="bg-white pt-10 w-[85%] mx-auto h-[95%]">
                            <div>
                            <img className="w-32 mx-auto" src={modal} />
                            </div>
                            <div className="text-center pt-4 w-[90%] mx-auto">
                                <p className="text-black font-bold text-2xl">{txt.folks[lang]}</p>
                                <p className="text-[#3B3B3B] font-semibold text-xl">{txt.exhaustedtodaylimit[lang]}</p>
                                <p className="text-black text-base">{txt.youcanchk[lang]}</p>
                           </div>
                            <div className="text-center pt-5 w-[80%] mx-auto cursor-pointer">
                                <button className="bg-black text-white w-full py-1 text-xl rounded-2xl" onClick={openHome}>{txt.home[lang]}</button>
                            </div>
                            <div className="text-center pt-1 w-[80%] mx-auto cursor-pointer">
                            <button className=" bg-[#9D328D] border-2  w-full py-1 text-xl rounded-2xl" onClick={showScores} >{txt.scoreboard[lang]}</button>
                        </div>
                        </div>
                      </div>
        
        </div>
        </div>
        </div>
    )
}

export default UnlockModal