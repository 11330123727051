import axios from 'axios'
import { baseUrl, data, headers } from '../config/config';
import React, { useRef } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import editImg from "../components/themeimg/assets/editImage.png"

function SubPage() {
  const [inputState, setInputState] = useState()
  const navigate = useNavigate()
  const handle = useRef(null)

  const handleFocus = () => {
    handle.current && handle.current.focus()
  }

  const sendOtpRequest = (number) => {
    axios({
      url: baseUrl + '/requestOTP',
      method: 'post',
      data: { "msisdn": String(number) },
      headers: headers
    }).then((resp) => {
      if (resp.data.status === 'failed') {
        console.log("api failed")
      } else if (resp.data.status === 'success') {
        console.log("api successful")
        navigate('/otpPage', { state: { data: String(inputState) } })
      }
    })
  }

  const RestrictNumber = (e) => {
    let str = ""
    str += e.target.value
    var thenum = str.replace(/[^0-9.]/g, '').replace(/(\..?)\../g, '$1').replace(/^0[^.]/, '0');
    setInputState(thenum)

}

  return (
    <div className='max-w-[500px] mx-auto  h-[100vh]'>
      <div className='bg-subImage w-[90%] mx-auto mt-8 h-[14rem] bg-cover bg-no-repeat '></div>
      <div className='my-2 font-bold mx-auto w-[80%] text-lg text-center'> Trip to your DreamDestination is just a click away! </div>

      <div className=''>
        <input
          ref={handle}
          className='block mx-auto text-center bg-[#607AF1] outline-none py-1 rounded-sm w-[70%] text-black font-bold px-4'
          type="text"
          value={inputState}
          maxLength={10}
          onChange={RestrictNumber}
          placeholder="enter your number"
        ></input>
      </div>
      <div className='text-center mx-auto text-xs mt-4 font-bold '> kindly edit the msisdn if not correct <img onClick={handleFocus} className='inline-block w-4 ml-[0.25rem]' src={editImg} /></div>

      <div onClick={() => sendOtpRequest(inputState)} className=' text-white text-center text-sm py-[7px] px-5 font-bold bg-[#607AF1] w-[62%] mx-auto mt-4 rounded-2xl'>
        Activate Now
      </div>
    </div>
  )
}

export default SubPage