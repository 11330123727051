import React,{useContext} from 'react';
import headerImage from "../components/themeimg/assets/logo_new.png";
import backBtn from "../components/themeimg/assets/back-btn.png"
import modal from "../components/themeimg/assets/topUP.png"
import { useNavigate } from 'react-router-dom';
import { Context } from '../Context/Context';
import { txt } from './text';
const GeneralModal = () => {
    const lang = localStorage.getItem("appLang");
    const {toggleSubModal,modalType,setShowUnlockModal} = useContext(Context) ;
     console.log("inside Mdoal") 
    const navigate=useNavigate();
   const gotoHome = () => {
       toggleSubModal();
       setShowUnlockModal(false)
       navigate("/home");
     }
  return (
    <div className="container z-[102] ">
    <div className="background-theme">
      <div className='contest-container '>
    <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
              <div className='back-arrow back-btn-quiz' style={{backgroundColor:"white"}} onClick={() => gotoHome()}>
                    <div className='back-arrow-button back-btn-align'>
                      <img src={backBtn} alt="back"/>
                    </div>
                  </div>
                  <img className="w-40 relative right-6 mx-auto h-16" src={headerImage} />
                  </div>
                  <div className='bottom-white-bg bg-[#FAFAFA] bg-cover bg-no-repeat relative pt-12'>
                    <div className="bg-white pt-20 w-[85%] mx-auto h-[95%]">
                        <div>
                        <img className="w-32 mx-auto" src={modal} />
                        </div>
                        <div className="text-center pt-10 w-[90%] mx-auto">
                            {modalType==="failure" ? 
                             <p className="text-black/60 text-xl">{txt.failureText[lang]}</p>:
                             <div className='flex justify-center items-center' style={{flexDirection:"column"}}>
                            <p className="text-black font-bold text-2xl">{txt.congrats[lang]}</p>
                             <p className="text-black/60 text-xl">{txt.pendingtxt[lang]}</p>
                          
                             <div className="text-center pt-8 w-[80%] mx-auto cursor-pointer">
                             <button className="bg-[#9D328D] text-black w-full py-1 text-xl rounded-2xl" onClick={gotoHome}>
                             {txt.ok[lang]}
                             </button>
                             </div>
                             </div>
                            }
                        
                        </div>
                    
                    </div>
                  </div>
    
    </div>
    </div>
    </div>
  )
}

export default GeneralModal
