import React,{useState,useEffect, useContext} from 'react';
import './style/home.css';
import axios from 'axios';
import { baseUrl, data, headers } from '../config/config';
import icon_destination  from './themeimg/assets/place.png';
import icon_touristguide from './themeimg/assets/map.png'
import icon_cuisine from './themeimg/assets/local_dining.png';
import icon_culture from './themeimg/assets/photo_library.png';
import MenuBar from './MenuBar'
import {Link,useNavigate} from 'react-router-dom';
import LoaderComponent from './Loader';
import headerImage from './themeimg/assets/logo_new.png'
import { Context } from '../Context/Context';
import Lang from './Lang'
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';
const ImgMapping = {'Destination':icon_destination,'Tour guide':icon_touristguide,'Kitchen':icon_cuisine,'Culture':icon_culture};
const Home = (props) => {
    const navigate = useNavigate();
    const[loader,setLoader] = useState();
    const [openHam,setHamburger] = useState(true);
    const configData = props.configData;
    const [showPlayBtn,SetShowPlayBtn] = useState(false);
    const {pageLoader , handlePageLoader} = useContext(Context)
    const [contestApiData,setContestApiData] = useState();
    const [openLang,setOpenLang] = useState(false)
    const lang = localStorage.getItem("appLang")
    const [score,setScore]=useState("");
    useEffect(() => {
        let guiEvents={};
        guiEvents["page"]="home"
        guiEvents["event"]="page_view"
        SendGuiDataEvents(guiEvents)
        getAvailCont();
    },[])


    const getAvailCont = async () => {
     try{
        let guiEvents={};
        guiEvents["page"]="home"
        guiEvents["event"]="availableContest_request"
        SendGuiDataEvents(guiEvents);
        const response = await axios({
            method: 'post',
            url: baseUrl+'getAvailableContests',
            data: {...data , "msisdn": localStorage.getItem('msisdn') },
            headers: headers,
        })

        console.log("response --> ",response.data.contests);
        let guiResEvents={};
        guiResEvents["page"]="home"
        guiResEvents["event"]="availableContest_response"
        guiResEvents["status"]=response.data.subStatus
        SendGuiDataEvents(guiEvents)
        setContestApiData(response.data.contests);
        setScore(localStorage.getItem("score") || response?.data?.contests?.[0].score )
        console.log("score",response?.data?.contests?.[0].score )
        localStorage.setItem("Score",response?.data?.contests?.[0]?.score)
    }
    catch (error) {
        console.error("Error occurred during API call:", error);  
        let guiEventsError = {};
        guiEventsError["page"] = "home";
        guiEventsError["event"] = "availableContest_response_error";
        guiEventsError["error_status"] = error.response?.status || "unknown";
        guiEventsError["error_message"] = error.message || "Error occurred during the API call";
        SendGuiDataEvents(guiEventsError);
    }
};
    function  menuListPage(data) {
        sessionStorage.setItem("pageUrl",data.url);
        sessionStorage.setItem("header",data.title);
    }
    const checkSubscriptionApi = async (e) => {
        try{
            let guiEvents={};
        guiEvents["page"]="home"
        guiEvents["event"]="checksub_request"
        SendGuiDataEvents(guiEvents);
        setLoader(true);
        const response = await axios({
            method: 'post',
            url: baseUrl+'checkSub',
            data: {uid: localStorage.getItem("uid")},
            headers: headers,
        })

        let guiResEvents={};
        guiResEvents["page"]="welcome"
        guiResEvents["event"]="checksub_response"
        guiResEvents["status"]=response.data.subStatus
        SendGuiDataEvents(guiResEvents)
        if ((response.data.subStatus).toUpperCase() === 'ACTIVE') {
            navigate('/contest')
        }else if ((response.data.subStatus).toUpperCase() === 'NEW') {
            window.open(localStorage.getItem("subUrl"),"_self")
        }

        else {
            return  window.location.replace(configData.sub_url)
        }
        setLoader(false)
        console.log("url",props.configData.menu_list[0].url)
        sessionStorage.setItem("pageUrl",props.configData.menu_list[0].url)
        sessionStorage.setItem("header",props.configData.menu_list[0].title)
     }
     catch (error) {
        console.error("Error occurred during API call:", error);  
        let guiEventsError = {};
        guiEventsError["page"] = "home";
        guiEventsError["event"] = "checksub_response_error";
        guiEventsError["error_status"] = error.response?.status || "unknown";
        guiEventsError["error_message"] = error.message || "Error occurred during the API call";
        SendGuiDataEvents(guiEventsError);
    }
    }

    function hamburger(openHam){
        setHamburger(!openHam)
    }
    function imageFullyLoaded(){
        SetShowPlayBtn(true)
    }

    return (
        <>
        <div className='home-page-container'>
            <div className='container theme-bg'>
                {
                     <MenuBar openHam={openHam} setHamburger={setHamburger} drawer_list={props.configData.drawer_list} openLang={openLang} setOpenLang={setOpenLang}/>
                }
                {openLang &&
                    <div className=''>
                         <Lang openLang={openLang} setOpenLang={setOpenLang} />
                    </div>                   
                }
                {pageLoader && <LoaderComponent />}
                <div className='section-home bg-white bg-no-repeat bg-cover'>
                    <div className='container-nav-bar'>
                        <div className='nav-bar'>

                                <div className='hamburger ' onClick={()=>hamburger(openHam)}>
                                    <div className='ham-1'></div>
                                    <div className='ham-1'></div>
                                    <div className='ham-1'></div>
                                </div>

                            <img className="dreamTravelLogo" src={headerImage} />
                        </div>
                    </div>

                    {/* below is the code for strip where score should be shown */}
                    <div className='points-class  bg-[#9D328D]'>
                       {contestApiData ?  <p className="has-text-centered has-text-white font-bold ">{txt.score[lang]} : {contestApiData.length > 0 && contestApiData[0] ? contestApiData[0].score : score?score:'0'}</p> : <div className='loader-btn'></div>}
                    </div>
                    <div className='img-section'>
                        <figure className='mg'>
                            <img src={localStorage.getItem("thumbUrl")} onLoad={() => {imageFullyLoaded() }}></img>
                        </figure>
                        {showPlayBtn && <div className='text has-text-centered  txt-on-img'>
                                <div className='button is-primary is-rounded is-uppercase' onClick={(e) =>checkSubscriptionApi(e)}>{loader && <div className='loader-btn'></div>}{ !loader &&  props.configData.subStatusMsg}</div>
                            </div>
                        }
                    </div>
                  
                    <div>
                        <div className='menu-list theme-bg'>
                                {
                                    props.configData.menu_list &&  props.configData.menu_list.map((data,key) => {
                                        return (
                                            <div className='list-items' key={key}>
                                                <div >
                                                    <Link onClick={()=> menuListPage(data)} to={`/menuList/${(data.title).replaceAll(" ","")}`} >
                                                        <div>
                                                            <figure>
                                                                <img  src={ImgMapping[data.menu_id]} alt="" />
                                                                    <figcaption>{data.title}</figcaption>
                                                            </figure>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}
export default Home;
