import React, { useContext, useState } from "react";
import { Context } from "../Context/Context";
import axios from "axios";
import { baseUrl, headers } from "../config/config";
import headerImage from "./themeimg/assets/logo_new.png";
import LoaderComponent from "./Loader";
import { txt } from "./text";
import backBtn from "./themeimg/assets/back-btn.png";
import { SendGuiDataEvents } from "../CommonScript";

function SubModal() {
  const { subModalData, handleSubModal,setModalType,setModalText, toggleSubModal,setShowUnlockModal } =
    useContext(Context);
  const [loaderMainScreen, setLoaderMainScreen] = useState(false);
  const lang = localStorage.getItem("appLang");
  const checkSubscriptionApi = async () => {
    try{
      let guiEvents={};
      guiEvents["page"]="unlockQuestion"
      guiEvents["event"]="checksub_request"
      SendGuiDataEvents(guiEvents)   
      setLoaderMainScreen(true);
      const response = await axios({
      method: "post",
      url: baseUrl + "checkSub",
      data: { uid: localStorage.getItem("uid") },
      headers: headers,
    });
    setLoaderMainScreen(false);
    let guiEvents1={};
      guiEvents1["page"]="unlockQuestion"
      guiEvents1["event"]="checksub_response"
      guiEvents1["status"]=response?.data?.subStatus;
      SendGuiDataEvents(guiEvents1)
    if (response?.data?.subStatus.toUpperCase() === "ACTIVE") {
      window.open("/home", "_self");
    } else if (response?.data?.subStatus.toUpperCase() === "NEW") {
      window.open(localStorage.getItem("subUrl"), "_self");
    } else {
      window.open(localStorage.getItem("subUrl"), "_self");
    }
  }catch (error) {
    console.error("Error occurred during API call:", error);  
    let guiEventsError = {};
    guiEventsError["page"] = "unlockQuestion";
    guiEventsError["event"] = "checksub_response_error";
    guiEventsError["error_status"] = error?.response?.status || "unknown";
    guiEventsError["error_message"] = error?.message || "Error occurred during the API call";
    SendGuiDataEvents(guiEventsError); 
  }
  };

  const unlockApi = async () => {
    try
    {   
      let guiEvents={};
      guiEvents["page"]="unlockQuestion"
      guiEvents["event"]="unlock_request"
      SendGuiDataEvents(guiEvents)   
     setLoaderMainScreen(true);
    const response = await axios({
      method: "post",
      url: baseUrl + "unlock",
      data: { msisdn: localStorage.getItem("msisdn") },
      headers: headers,
    });
    setLoaderMainScreen(false);
    console.log("the unlock api repsonse is :::",response)
    let guiEvents1={};
    guiEvents1["page"]="unlockQuestion"
    guiEvents1["event"]="unlock_response"
    guiEvents1["status"]=response?.data?.subStatus
    SendGuiDataEvents(guiEvents1)   
    if (response.data.status.toUpperCase() === "SUCCESS") {

      if(response.data.subStatus.toUpperCase() === "LOWBALANCE")
      {
        window.open("/unlockfail", "_self");
      }
      else if(response.data.subStatus.toUpperCase() === "PENDING")
      {
        setShowUnlockModal(true)
        setModalType("success")
      }
      else if(response.data.subStatus.toUpperCase() === "SUCCESS")
      {
        window.open("/congrats", "_self");
      }
      else 
      {
        setModalText("Something went wrong");
        setShowUnlockModal(true)
        setModalType("failure")
      }
    } else {
      console.log("Top up failed")
      window.open("/unlockfail", "_self");
    }
  }catch (error) {
    console.error("Error occurred during API call:", error);  
    let guiEventsError = {};
    guiEventsError["page"] = "unlockQuestion";
    guiEventsError["event"] = "unlock_response_error";
    guiEventsError["error_status"] = error?.response?.status || "unknown";
    guiEventsError["error_message"] = error?.message || "Error occurred during the API call";
    SendGuiDataEvents(guiEventsError); 
  }
  };

  const unlock = () => {
    unlockApi();
  };

  const closeModal = (value) => {
    if (subModalData.btn.length > 1 && value === "1") {
      toggleSubModal(false);
      console.log("Yes btn clicked");
    } else if (subModalData.btn.length > 1 && value === "2") {
      console.log("No btn clicked");
    } else {
      console.log("Okay btn clicked");
      toggleSubModal(false);
      console.log("subModalData ------->", subModalData);
      if (subModalData.id === "unsub") {
        checkSubscriptionApi();
        console.log("unsub clicked");
      } else if (subModalData.id === "warning") {
        toggleSubModal(false);
      }
    }
    console.log("close modal clicked");
    handleSubModal(subModalData, false);
  };

  const gotoUnlock = () => {
    window.open("/unlock", "_self");
  };

  return (
    <div className="absolute w-full bg-black/60 max-w-[500px] h-full right-0 my-0 mx-auto left-0 bg-green_bg_pic z-[101]">
      {loaderMainScreen ? (
        <LoaderComponent />
      ) : (
        <div className="w-full absolute bg-white bottom-0 left-0 rounded-xl right-0 mx-auto border-4 border-black/60 ">
          <div className="upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm">
            <div
              className="back-arrow back-btn-quiz"
              style={{ backgroundColor: "white" }}
              onClick={() => gotoUnlock()}
            >
              <div className="back-arrow-button back-btn-align">
                <img src={backBtn} alt="back" onClick={()=>window.open("/home","_self")}/>
              </div>
            </div>
            <img className="w-36 relative mx-auto h-16 pt-1" src={headerImage} />
          </div>
          <div className="font-bold pt-6 text-center text-xl w-[70%] mx-auto">
            {subModalData.body}
          </div>
          <div className="pb-4 pt-3 text-center px-10">
            <p className="text-2xl text-[#9D328D] font-bold">
              {txt.subCnf[lang]}
            </p>
            <p className="text-lg text-black/70 font-bold">
              {txt.doubleCnf[lang]}
            </p>
            <p className="text-3xl text-black font-bold py-6">
              {txt.pack[lang]}
            </p>
            <p
              className="mt-2  py-2 rounded-full font-bold text-white  bg-[#9D328D]  bg-darkGreen shadow-xl cursor-pointer"
              onClick={unlock}
            >
              {subModalData?.btn[0]}
            </p>
            {subModalData.btn.length > 1 && (
              <p
                className="font-bold mt-2 py-2 rounded bg-[#607AF1]  text-white  bg-bgGreenIslam border"
                onClick={() => closeModal("2")}
              >
                {subModalData?.btn[1]}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SubModal;
