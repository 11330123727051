import React from 'react'
import './style/loader.css'

export default function Loader(props) {
  return (
    <div className='loader-container'>
        <div className='loader'></div>
    </div>
  )
}
