import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import headerImage from './themeimg/assets/logo_new.png'
import modal from "./themeimg/assets/modalArt1.png"
import { baseUrl, data, headers } from '../config/config';
import axios from 'axios';
import LoaderComponent from './Loader';
import ScoreModal from './ScoreModal'
import { txt } from "./text";
import backBtn from "./themeimg/assets/back-btn.png";

function QuizModal () {

    const [data,setData] = useState()
    const [loaderMainScreen,setLoaderMainScreen] = useState(false);
    const [score,setScore] = useState()
    const [showScore,setShowScore] = useState(false);
    const [topUpCount,setTopUpCount]=useState();
    const lang = localStorage.getItem("appLang")
    const [contestApiData,setContestApiData] = useState();
    const navigate=useNavigate();
    const points=localStorage.getItem("score")
    // const score = localStorage.getItem("Score")

    const getAvailableLevelsForUnlockApi = async () => {
        // data['msisdn']= msisdn;
        setLoaderMainScreen(true)
        const response = await axios({
            method: 'post',
            url: baseUrl+'getAvailableLevelsForUnlock',
            data: {...data , msisdn: localStorage.getItem('msisdn')},
            headers: headers,
        })
        setData(response?.data);
        setLoaderMainScreen(false);
        setTopUpCount(response?.data?.count)
        console.log("count",response?.data?.count)
        // setScore(response.data.score)
        // localStorage.setItem("Score",response.data.score)
      }

      const getAvailCont = async () => {

        const response = await axios({
            method: 'post',
            url: baseUrl+'getAvailableContests',
            data: {...data , "msisdn": localStorage.getItem('msisdn') },
            headers: headers,
        })

        console.log("response --> ",response?.data?.contests)
        setContestApiData(response?.data?.contests)
        console.log("score",response?.data?.contests?.[0]?.score)
        localStorage.setItem("Score",response?.data?.contests?.[0]?.score)
        setScore(response?.data?.contests?.[0]?.score || points )
        // setPageLoader(false)
    }
  console.log("topUpCount",topUpCount)
    const openHome = () => {
        navigate("/home")
      }

      const openUnlock =()=>{
        navigate("/unlock")
      }

      const showScores =()=>{
        // setShowScore(true)
        navigate("/scoreboard")
      }

      useEffect(()=>{
        getAvailableLevelsForUnlockApi()
        getAvailCont()
      },[])

    return(
        <div className="container ">
             {/* {showScore &&
            <div>
                 <ScoreModal score={score} showScore={showScore} setShowScore={setShowScore} />
            </div>
             } */}
        <div className="background-theme">
        {loaderMainScreen ? <LoaderComponent /> :
          <div className='contest-container '>
           
        <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
                  <div className='back-arrow back-btn-quiz'style={{backgroundColor:"white"}} onClick={openHome}>
                        <div className='back-arrow-button back-btn-align'>
                        {/* <p  className="arrow left "></p> */}
                         <img src={backBtn} alt="back"/>
                        </div>
                      </div>
                      <img className="w-40 relative right-6 mx-auto h-16" src={headerImage} />
                      </div>
                      <div className='bottom-white-bg bg-[#FAFAFA] bg-cover bg-no-repeat relative pt-12'>
                        <div className="bg-white pt-10 w-[85%] mx-auto h-[95%]">
                            <div>
                            <img className="w-32 mx-auto" src={modal} />
                            </div>
                            <div className="text-center pt-4 w-[90%] mx-auto">
                                <p className="text-black font-bold text-2xl">{txt.folks[lang]}</p>
                                <p className="text-[#3B3B3B] font-semibold text-xl">{txt.completedquestionsmain[lang]}</p>
                                <p className="text-[#3B3B3B] font-semibold text-xl">{txt.completedquestions[lang]}</p>
                                <p className="text-black text-base">{txt.youcanchk[lang]}</p>
                                {score && <p className="text-black  text-md">Your score is <span className="font-bold text-2xl">{score}</span>, want more information go to leaderboard</p>}
                            </div>
                           {topUpCount!=="" && topUpCount!==null  && topUpCount < 2  &&   <div className="text-center pt-5 w-[80%] mx-auto cursor-pointer">
                                <button className="bg-black text-white w-full py-1 text-xl rounded-2xl" onClick={openUnlock}>{txt.unlockmorequestion[lang]}</button>  
                            </div>
                            }
                            <div className="text-center pt-1 w-[80%] mx-auto cursor-pointer">
                            <button className=" text-black border-2 bg-[#FFCB00] w-full py-1 text-xl rounded-2xl" onClick={showScores} >{txt.checkscorebtn[lang]}</button>
                        </div>
                        </div>
                      </div>
        
        </div>}
        </div>
        </div>
    )
}

export default QuizModal