import React,{useState,useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { baseUrl, data, headers } from '../config/config';
import LoaderComponent from './Loader';
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';
export default function MenuBar(props) {
    const [drawerList,setDrawerList] = useState([]);
    const [data,setData] = useState()
    const [loaderMainScreen,setLoaderMainScreen] = useState(false);
    const lang = localStorage.getItem("appLang")
    const navigate = useNavigate()
    useEffect(()=>{
        setDrawerList(props.drawer_list);
    },[props.drawer_list])

    function hideMenuBar() {
       props.setHamburger(true)
    }

    function  menuListPage(data) {
        sessionStorage.setItem("pageUrl",data.url);
        sessionStorage.setItem("header",data.title);
    }
    const msisdn = sessionStorage.getItem("msisdn")


    const getAvailableLevelsForUnlockApi = async () => {
        try{
        let guiEvents={};
        guiEvents["page"]="menu"
        guiEvents["event"]="getAvailableLevelsForUnlockApi_request"
        SendGuiDataEvents(guiEvents);
        setLoaderMainScreen(true)
        const response = await axios({
            method: 'post',
            url: baseUrl+'getAvailableLevelsForUnlock',
            data: {...data , msisdn: localStorage.getItem('msisdn')},
            headers: headers,
        })
        setData(response.data);
        setLoaderMainScreen(false)
        console.log("data",response.data.status)
        let guiResEvents={};
        guiResEvents["page"]="menu"
        guiResEvents["event"]="getAvailableLevelsForUnlockApi_response"
        guiResEvents["status"]=response?.data?.status;
        SendGuiDataEvents(guiResEvents);
        if(response?.data?.count> 0 ){
            navigate("/unlock")
        }
        else{
            navigate("/unlockmodal")
        }
     }catch(error)
     {
       let guiEventsError = {};
       guiEventsError["page"] = "menu";
       guiEventsError["event"] = "getAvailableLevelsForUnlockApi_response_error";
       guiEventsError["error_status"] = error.response?.status || "unknown";
       guiEventsError["error_message"] = error.message || "Error occurred during the API call";
       SendGuiDataEvents(guiEventsError);
     }
      }
    
    const scoreBoardHandler = () => {
        navigate("/scoreboard")
    }

    const unsubHandler = () => {
        navigate("/unsub")
    }

    const unlockQuestionsHandler = () => {
        getAvailableLevelsForUnlockApi()
    }

    const galleryHandler = () => {
        navigate("/gallery")
    }
 
    return (
        <div id='mySidenav' className={props.openHam?'menu-bar ':'menu-bar active'} style={{height:"100%"}}>
               
                <div className='sidebar-heading relative sidebar-heading-title mb-4 bg-[#9D328D] bg-cover h-10  bg-no-repeat' style={{backgroundColor:"white"}}>
                <a href="#" className="close " onClick={() => {hideMenuBar()}}></a>
                            <div className=' h-full w-full flex flex-row items-center justify-center'>
                                    <div className=' w-full text-black pt-2' style={{backgroundColor:"white"}}>  {txt.menu[lang]}  </div>
                            </div>
                    </div>
                    {loaderMainScreen ? <LoaderComponent/>:
            <div className='link-tags' dir={lang === 'ar' ? 'rtl' : 'ltr'}>
                {
                  drawerList && drawerList.map((item,ind) => {
                        if(ind === 5){
                            return (
                                <div className='my-2 cursor-pointer'>
                                <div className='my-0 nav-link-single' style={{color:"#86888D"}} key={ind}   onClick={()=> scoreBoardHandler()}>{item.title}</div>
                                <hr />
                                </div>
                            )
                            return null;
                        }else if(ind === 2){
                            return (
                                <div className='my-2 cursor-pointer'>
                                <div className='my-0 nav-link-single ' style={{color:"#86888D"}} key={ind}   onClick={()=> unsubHandler()}>{item.title}</div>
                                <hr />
                                </div>
                            ) 
                        }
                        else if(ind === 3){
                            return (
                                <div className='mt-2 cursor-pointer'>
                                <div className='my-0 nav-link-single ' style={{color:"#86888D"}}key={ind}   onClick={()=> unlockQuestionsHandler()}>{item.title}</div>
                                <hr />
                                </div>
                            )
                        }
                        else if(ind === 0){
                            return (
                                <div className='my-2 cursor-pointer'>
                                <div className='my-2 nav-link-single 'style={{color:"#86888D"}} key={ind}   onClick={()=> galleryHandler()}>{item.title}</div>
                                <hr />
                                </div>
                            )
                        }
                        else{

                               return ( <div className='my-2 cursor-pointer'>
                                <Link className='my-2 nav-link-single' style={{color:"#86888D"}}to={`/navlist/${item.title.replaceAll(" ","")}`} key={ind}   onClick={()=> menuListPage(item)}>{item.title}</Link>
                                <hr />
                                </div>
                                )
                        }
                  })
                }
            </div>}

        </div>
    )
}
