import axios from 'axios'
import React, { useContext, useRef, useEffect } from 'react'
import { baseUrl, headers } from '../config/config';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from './Loader';
import { Context } from '../Context/Context';
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';
import backBtn from "./themeimg/assets/back-btn.png";
function GoToSub() {
    const { pageLoader , handlePageLoader} = useContext(Context)
    const navigate = useNavigate()
    const lang = localStorage.getItem("appLang")

    const unsubReqeustHandler = () => {
      try{
      let guiEvents={};
      guiEvents["page"]="subscription"
      guiEvents["event"]="unsub_request"
      SendGuiDataEvents(guiEvents)   
      handlePageLoader(true)
      axios({
          url: baseUrl + '/unsubscribe',
          method: 'post',
          data: { msisdn: localStorage.getItem('msisdn')},
          headers: headers
      }).then((resp) => {
        let guiResEvents={};
          guiResEvents["page"]="subscription"
          guiResEvents["event"]="unsub_response"
          guiResEvents["status"]=resp?.data?.subStatus
          SendGuiDataEvents(guiResEvents)
        handlePageLoader(false)
          if (resp.data.status === 'failed') {
              navigate("/unsubfail")
          } else if (resp.data.status === 'success') {
              console.log("api successful")
              window.open(localStorage.getItem("subUrl"),"_self")
          }
      })
    }
    catch (error) {
      console.error("Error occurred during API call:", error);  
      let guiEventsError = {};
      guiEventsError["page"] = "subscription";
      guiEventsError["event"] = "subscription_response_error";
      guiEventsError["error_status"] = error.response?.status || "unknown";
      guiEventsError["error_message"] = error.message || "Error occurred during the API call";
      SendGuiDataEvents(guiEventsError); 
    } 
}

  const gotoHome = () => {
    navigate("/home");
  }

  const ShowSubPage = () =>{
    unsubReqeustHandler()
  }

    return (
        <div className='max-w-[500px] mx-auto  h-[100vh]'>
            {pageLoader && <LoaderComponent />}
            <div className='upper-yellow-bg backdrop-blur-sm pt-4 bg-[#9D328D]'>
                  <div className='back-arrow back-btn-quiz'  onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                          <img src={backBtn} alt="back"/>
                        </div>
                      </div>
                    <h5 className='has-text-black has-text-centered mt-2'>{txt.unsub[lang]}</h5>
                  </div>
        <div className='bg-Sub w-[90%] mx-auto mt-8 h-[14rem] bg-cover bg-center bg-no-repeat '></div>
        <div className='my-2 font-bold mx-auto w-[80%] text-lg text-center pt-10'> {txt.srymsg[lang]}</div>
       <div className='pt-10'> <div className='button is-unsub mx-auto' onClick={ShowSubPage}><button>{txt.gobacktosub[lang]}</button></div></div>   
              </div>
    )
}

export default GoToSub