import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { SendGuiDataEvents } from '../CommonScript';
import { Context } from '../Context/Context';
import './style/langModal.css'
import { txt } from './text';

function Lang({openLang,setOpenLang}) {
  const navigate = useNavigate();
  const lang = localStorage.getItem("appLang")
  useEffect(()=>
    {
      let guiEvents={};
      guiEvents["page"]="language"
      guiEvents["event"]="page_view"
      SendGuiDataEvents(guiEvents)
    },[])
  
  const langChangeHandler = (lang) => {
    localStorage.setItem("appLang", lang)
    navigate('/')
    window.location.reload()
  }
  return (
    <div className={`modal-overlay ${openLang ? 'active' : ''} max-w-[500px] h-full`}>
    <div className='h-48  max-w-[500px] z-2000 mx-auto' style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    <div className='border-2 border-black w-[300px] bg-white rounded mx-auto h-full z-100'>
      <div className='pt-2 text-right pr-4 font-bold cursor-pointer text-black w-full' onClick={()=>setOpenLang(false)}>X</div>
      <div className='w-full text-center text-[#795891] font-bold text-2xl'>{txt.chooselang[lang]}</div>
    <div className=' pt-6 w-[50%] mx-auto cursor-pointer'>
      <div className=''>
        <div onClick={() => langChangeHandler("fr")} className='h-8 w-contain px-4 border-2 bg-[#EDE9E6] text-black rounded-lg text-center'>Français</div>
        <div onClick={() => langChangeHandler("ar")} className='h-8 w-contain px-4 border-2  bg-[#EDE9E6] text-black rounded-lg mt-4 text-center'>عربي</div>
      </div>
    </div>
    </div>
</div>
</div>
  )
}

export default Lang;