export const txt = {
    menu: {
      fr:"Menu",
      ar:"القائمة",
      en:"Menu"
    },
    score: {
        fr: "Score",
        ar: "النتيجة",
        en:"Points"
    },
    unlockquestion: {
        fr: "Déverrouiller les questions",
        ar: "فتح الأسئلة",
        en:"Unlock 10 more questions"
    },
    unlockat5mad: {
        fr: "Débloquer à 5 DH",
        ar: "فتح في 5 دراهم",
        en:'Unlock at 500UGX'
    },
    confirmyoursubs: {
        fr: "Confirmez votre souscription",
        ar: "تأكيد اشتراكك",
    },
    activatedt: {
        fr: "Voulez-vous débloquer plus de questions",
        ar: "هل تريد فتح المزيد من الأسئلة",
    },
    confirmsubs:{
        fr: "Confirmez votre souscription",
        ar: "تأكيد اشتراكك",
    },
    lowbalance: {
        fr: "Solde insuffisant",
        ar: "الرصيد غير الكافي",
        en:"Insufficient balance"
    },
    lbmsg: {
        fr: "Votre solde est insuffisant pour souscrire au Pass TRAVEL QUIZ. Veuillez recharger votre compte puis réessayer à nouveau.",
        ar: "رصيدك غير كافٍ لتشغيل خدمة TRAVEL QUIZ . الرجاء إعادة شحن حسابك ثم حاول مرة أخرى.",
        en:"Your balance is insufficient to subscribe to the TRAVEL QUIZ Pass. Please recharge your account and try again."
    },
    completedquestionsmain:
    {
        en:"You did a great job."
    },
    tryagain: {
        fr: "Essayer à nouveau",
        ar: "حاول من جديد",
        en:"Try Again"
    },
    congrats: {
        fr: "Félicitations",
        ar: "تهانينا",
        en:"Congratulations"
    },
    congtxt: {
        fr:"Votre nouveau pack de question à été débloqué avec succès",
        ar: "تم فتح حزمة الأسئلة الجديدة بنجاح",
        en:'Your next set of questions has been succesfully unlocked.'
    },
    pendingtxt:
    {
       en:"Your top-up request has been processed and you will be notified by the notification."
    },
    failureText:
    {
        en:"Something went wrong."
    },
    playnow: {
        fr: "Fermer",
        ar: "اغلاق",
        en:"Play Now"
    },
    questions: {
        fr: "",
        ar: "",
    },
    folks: {
        fr: "C'est tout pour maintenant",
        ar: "هذا كل شيء الى حد الآن",
        en:"That's all folks"
    },
    completedquestions: {
        fr: "Vous avez répondu à toutes les questions",
        ar: "لقد أجبت على جميع الأسئلة",
    },
    checkscorebtn:{
        fr: "vérifier le score",
        ar: "النتيجة",
        en:"Go To Leaderboard"
    },
    unlockmorequestion: {
        fr: "débloquez de nouvelles questions",
        ar:"فتح أسئلة جديدة",
        en:"Unlock More Questions"
    },
    yourscore: {
        fr: "Votre score est",
        ar: "نتيجتك هي ",
    },
    ok :{
        fr:"D'accord",
        ar: "حسنًا",
        en:"Okay"
    },
    gallery: {
        fr: "Galerie photos",
        ar: "معرض الصور",
        en:'Destination Photo Gallery'
    },
    leaderboard:
    {
      en:"Leaderboard"
    },
    question:{
      en:"Question"
    },
    unsub: {
        fr: "Se désabonner",
        ar: "إلغاء الاشتراك",
        en:"Unsubscribe"
    },
    unsubtxt: {
        fr: "Cliquez sur OK pour confirmer votre demande de désinscription. Une fois confirmée, vous recevrez un SMS de confirmation après traitement de votre demande.",
        ar: "اضغط على الزر OK لتأكيد طلب إلغاء الاشتراك الخاص بك. بمجرد التأكيد ، ستتلقى رسالة تأكيد SMS بعد معالجة الطلب",
        en:"Click OK to confirm your unsubscribe request. Once confirmed, we will process your request and we will inform you by SMS."
    },
    cnfrmunsub:{
        fr: "Confirmer la désinscription",
        ar: "تأكيد إلغاء الاشتراك",
        en:"Confirm Unsubscription"
    },
    srymsg: {
        fr: "Nous sommes navrés de vous voir partir",
        ar: "نحن آسفون لمغادرتك",
    },
    gobacktosub: {
        fr: "Rejoindre à nouveau.",
        ar: "انضم مرة أخرى.",
    },
    oops: {
        fr: "Oops",
        ar: "عفوًا",
        ern:'Oops'
    },
    exhaustedtodaylimit: {
        fr: "vous avez épuisé votre limite hebdomadaire de questions. Veuillez revenir demain pour débloquer plus de questions.",
        ar: "لقد استنفدت الحد الأسبوعي للأسئلة. المرجو العودة غدا لفتح المزيد من الأسئلة.", 
        en:'You  did a great job.' 
    },
    scoreboard: {
        fr: "Tableau de bord",
        ar: "لوحة الدرجات",
        en:'Go To Leaderboard'
    },
    chooselang: {
        fr: "Choisissez la langue",
        ar: "اختر اللغة",
    },
    unlockset: {
        fr: "vous ne pouvez débloquer qu'un seul ensemble",
        ar: "يمكنك فتح مجموعة واحدة فقط",
    },
    week1:{
        fr:"5MAD",
        ar:"5MAD",
    },
    subCnf:
    {
        en:"Confirm your subscription"
    },
    doubleCnf:
    {
        en:"Do you want to unlock more questions?"
    },
    pack:
    {
        en:"500UGX"
    },

    ques:{
        fr: "des questions",
        ar: "أسئلة",
    },
    youcanchk: {
        fr: "vous pouvez vérifier votre score sur le tableau de bord",
        ar: "يمكنك التحقق من درجاتك على لوحة النتائج",
        en:'Next Quiz will be available tomorrow.'
    },
    hurray: {
        fr: "Hourra!",
        ar: "هنيئًا!",
    },
    inching: {
        fr: "Vous approchez des vacances de vos rêves !",
        ar: "أنت تقترب من إجازة أحلامك!",
    },
    unsubSuccess:{
      en:"Unsubscribed"
    },
    unsubSuccessmsg:{
        en:"You have unsubcribed successfully"
      },
    unsubfail: {
        fr: "échec de désabonnement",
        ar: "فشل إلغاء الاشتراك",
        en:"Unsubscription failure."
    },
    unsubfailmsg: {
        fr: "Désolé, votre demande de désabonnement a échoué",
        ar: "عذرًا، لقد فشل طلب إلغاء الاشتراك",
        en:"Sorry, your unsubscription request failed."
    },
    home: {
        fr: "Maison",
        ar: "بيت",
        en:'Home'
    },
    welcome: {
        fr: "Bienvenue au concours Voyage de rêve",
        ar: "مرحبا بكم في مسابقة رحلة الحلم",
    },
    thanku: {
        fr: "Merci",
        ar: "شكرًا لك",
        en:"Thank You"
    },
    confirmunlock: {
        fr: "Confirmer le déverrouillage",
        ar: "تأكيد الفتح",
        en:'Confirm Unlock'
    },
    unlockHeading: {
        fr: "Confirmer le déverrouillage",
        ar: "تأكيد الفتح",
        en:"Unlock Questions"
    },
    tensectxt: {
        fr: "Vous avez 10 secondes pour répondre a chaque question",
        ar: "لديك 10 ثانية للإجابة على كل سؤال",
        en:"Answer 10 Questions every day"
    }
}