import React from 'react'
import backButton from '../themeimg/assets/back.png'
import { useNavigate } from 'react-router-dom';

function Culture() {
    const navigate = useNavigate();
  return (
    <div className='border-2 border-black h-screen'>
    <div className='border-2 border-black h-20 flex bg-[#E1DAD3]'>
        {/* <div className='border-2 border-black my-auto w-4 ml-4 h-4'></div> */}
        <img className='w-6 my-auto ml-4' onClick={() => navigate(-1)} src={backButton} />
        <div className="my-auto mx-auto font-bold relative right-4 text-3xl">Culture</div>
    </div>
</div>
  )
}

export default Culture