import React, { useEffect, useRef, useState } from 'react'
import {useNavigate,useLocation} from 'react-router-dom';
import { baseUrl, data, headers } from '../config/config';
import './style/quiz.css'
import axios from 'axios';
import MsgAlert from './MsgAlert';
import LoaderComponent from './Loader';
import './style/home.css';
import headerImage from './themeimg/assets/logo_new.png'
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';
export default function Quiz(props) {
  const location = useLocation();
  const timerIntervalRef = useRef(null);
  const [selectedAns,setSelectedAns] = useState(null);
  const [queData,setQueData] = useState(location.state.nextQue);
  const [availableLevel,setAvailableLevel] = useState(location.state.availableLevel);
  const [radioChecked,setRadioChecked] = useState(false);
  const [checkAnsData,setCheckAnsData] = useState();
  const [alertBox,setAlertBox] = useState(false);
  const [submitBtnShow,setSubmitBtn] = useState(true)
  const [loaderMainScreen,setLoaderMainScreen] = useState(false);
  const [timer, setTimer] = useState(10);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const lang = localStorage.getItem("appLang");
  const [questionCount, setQuestionCount] = useState(1);

  const navigate = useNavigate();
  
  function optionSelected(key) {
    setRadioChecked(true)
    setSelectedAns(key+'')
  }

  //get question from api
  const getNextQuestionApi = async () => {
    try{
    let guiEvents={};
    guiEvents["page"]="quiz"
    guiEvents["event"]="nextQuestion_request"
    SendGuiDataEvents(guiEvents)
    setLoaderMainScreen(true)
    const response = await axios({
        method: 'post',
        url: baseUrl+'getNextQuestion',
        data: {...data , msisdn: localStorage.getItem('msisdn')},
        headers: headers,
    })
    setQueData(response.data);
    let guiResEvents={};
    guiResEvents["page"]="quiz"
    guiResEvents["event"]="nextQuestion_response"
    guiResEvents["status"]=response.data.subStatus
    SendGuiDataEvents(guiResEvents)
    if (response.data.subStatus === 'EndOfQuestion') {
       navigate("/quizmodal")
    }
    setQuestionCount(prevCount => prevCount + 1);
    setLoaderMainScreen(false)
    setSubmitBtn(true)
  }
  catch (error) {
    console.error("Error occurred during API call:", error);  
    let guiEventsError = {};
    guiEventsError["page"] = "quiz";
    guiEventsError["event"] = "nextQuestion_request_error";
    guiEventsError["error_status"] = error.response?.status || "unknown";
    guiEventsError["error_message"] = error.message || "Error occurred during the API call";
    SendGuiDataEvents(guiEventsError);

  } finally {
    setLoaderMainScreen(false);
  }
  }
 // check ans api
 const checkAnswerApi = async () => {
  try{
    let guiEvents={};
    guiEvents["page"]="quiz"
    guiEvents["event"]="checkAnswer_request"
    SendGuiDataEvents(guiEvents)
  data['answer'] = selectedAns ? selectedAns : "0" ;
  data['questionId'] = queData.questionId;
  const response = await axios({
    method: 'post',
    url: baseUrl+'checkAnswer',
    data: {...data , msisdn: localStorage.getItem("msisdn")},
    headers: headers,
  })
  console.log('check ans=>',response.data);
  let guiResEvents={};
  guiResEvents["page"]="quiz";
  guiResEvents["event"]="checkAnswer_response";
  guiResEvents["status"]=response.data.subStatus;
  SendGuiDataEvents(guiResEvents)
  setAlertBox(true);
  setSubmitBtn(false);
  setCheckAnsData(response.data);
  localStorage.setItem("score",response?.data?.score);
  setTimeout(() => {
    getNextQuestionApi();
    setRadioChecked(false)
    setAlertBox(false);
  },1700);
 }
 catch (error) {
  console.error("Error occurred during API call:", error);  
  let guiEventsError = {};
  guiEventsError["page"] = "quiz";
  guiEventsError["event"] = "checkAnswer_response_error";
  guiEventsError["error_status"] = error.response?.status || "unknown";
  guiEventsError["error_message"] = error.message || "Error occurred during the API call";
  SendGuiDataEvents(guiEventsError);
} 
}

// check submitted ans
function CheckAns() {
  setLoaderMainScreen(true);
  checkAnswerApi();
}

function optionSelected(key) {
  if (!isOptionSelected) { // Allow selection only if no option is selected yet
    setRadioChecked(true);
    setSelectedAns(key + '');
    setIsOptionSelected(true);
  }
}
useEffect(() => {
  let guiEvents={};
  guiEvents["page"]="quiz"
  guiEvents["event"]="page_view"
  SendGuiDataEvents(guiEvents)
}, []);

useEffect(() => {
  // Start the timer when the question is displayed
  if (queData && !isOptionSelected) {
    setTimer(10); // Reset timer to 10 seconds for each new question
    const timerId = setInterval(() => {
      setTimer((t) =>{
          if(t > 0){
            return t-1;
          }    
          else{
            clearInterval(timerId);
            return 0;
          }
      } )// Decrease timer every second
    }, 1000);
    timerIntervalRef.current = timerId;

    // Clean up the interval on unmount or before resetting the timer
    return () => clearInterval(timerId);
  }
}, [queData, isOptionSelected]);

useEffect(() => {
  if (timer === 0 || selectedAns) {
    // Timer has reached 0 or an option is selected, automatically check the answer and load the next question
    CheckAns();
  }
}, [timer, selectedAns, isOptionSelected]);


useEffect(() => {
  if (queData) {
    setIsOptionSelected(false);
    setSelectedAns(null) // Reset option selection when a new question is displayed
  }
}, [queData]);

return (
  <>
    {(queData || availableLevel) ?
      <div>
        <div>
          <div className="container ">
            <div className="background-theme">
              <div className='contest-container '>
                <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
                  <img className="w-40 relative mx-auto h-16" src={headerImage} />
                </div>
                <div className='bottom-white-bg bg-[#F8F8F8] bg-cover bg-no-repeat relative pt-12'>
                  <div className='text-center'>
                    {txt.question[lang]} <span className='font-bold text-2xl'>{questionCount}</span>
                  </div>
                  <div className={queData.subStatus == 'EndOfQuestion' ? 'card card-height ' : 'card px-4 py-4 '}>
                    <div className='has-text-centered font-semibold'>{txt.ques[lang]}</div>
                    <div className=' has-text-centered float-right'>
                      <div className='timer-container w-16 '>
                        <div className='timer'>
                          {timer >= 10 ? `00:${timer}` : `00:0${timer}`}
                        </div>
                      </div>
                    </div>
                    <div className='card-container has-text-centered'>
                      {
                        queData.subStatus != 'EndOfQuestion' && queData && <>
                       {queData?.question_image_url && <img src={queData?.question_image_url} alt={txt.ques[lang]} className='mt-4 rounded-md w-48 h-48 aspect-square m-auto' />}
                          <div className='question-section'>
                            <div className='question-head font-bold pt-6 text-'>{queData.questionString}</div>
                            <div className='question-options'>
                              {loaderMainScreen && <LoaderComponent />}
                              {
                                queData?.optionString?.map((option, key) => {
                                  return (
                                    <div className='que-option-container' key={key} onClick={() => optionSelected(key + 1)}>
                                      <label htmlFor={key} className='ques-option-span'>
                                        <input type='radio' id={key} value={option} name="options" checked={selectedAns == key + 1 ? radioChecked : false} />
                                        <span className="custom-radio"></span> {/* Custom radio button indicator */}
                                        <span className='ques-option ml-2'>{option}</span>
                                      </label>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                  <div className='contest-play-button'>
                  </div>
                  {alertBox && <MsgAlert checkAnsData={checkAnsData} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <LoaderComponent />
    }
  </>
)
}
