import React from "react";
import { Navigate } from "react-router-dom";
import headerImage from './themeimg/assets/logo_new.png'
import { txt } from "./text";
import backBtn from "./themeimg/assets/back-btn.png";
import modal from "./themeimg/assets/modalArt1.png"
function UnsubSuccess () {

  const lang= localStorage.getItem("appLang");
    const gotoHome = () => {
        // localStorage.removeItem("uid");
        // localStorage.removeItem("msisdn")
        window.open("/home","_self")
      }

    return(
        <div className="container ">
        <div className="background-theme">
          <div className='contest-container '>
        <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
                  <div className='back-arrow back-btn-quiz' style={{backgroundColor:"white"}}onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                           {/* <p  className="arrow left "></p> */}
                            <img src={backBtn} alt="back"/>
                        </div>
                      </div>
                      <img className="w-40 relative right-6 mx-auto h-16" src={headerImage} />
                      </div>
                      <div className='bottom-white-bg bg-[#FAFAFA] bg-cover bg-no-repeat relative pt-12'>
                        <div className="bg-white pt-20 w-[85%] mx-auto h-[95%]">
                            <div>
                            {/* <img className="w-32 mx-auto" src={oops} /> */}
                            <img className="w-32 mx-auto" src={modal} />
                            </div>
                            <div className="text-center pt-10">
                                <p className="text-black font-bold text-2xl">{txt.unsubSuccess[lang]}</p>
                                {/* <p className="text-[#3B3B3B] font-semibold text-xl">{txt.unsubfail[lang]}</p> */}
                                <p className="text-black text-base">{txt.unsubSuccessmsg[lang]}</p>
                            </div>
                            <div className="text-center pt-8 w-[80%] mx-auto cursor-pointer">
                                <button className="bg-[#9D328D] text-white w-full py-1 text-xl rounded-2xl" onClick={gotoHome}>{txt.thanku[lang]}</button>
                            </div>
                        </div>
                      </div>
        
        </div>
        </div>
        </div>
    )
}

export default UnsubSuccess