
const getTotalDuration = (storageType) => {
  let totalDuraction
  if(storageType === "local"){
    totalDuraction= new Date().getTime() - localStorage.getItem("localStartTime")
  }else if(storageType === "session"){
    totalDuraction= new Date().getTime() - sessionStorage.getItem("sessionStartTime")
  }
  return totalDuraction
};

export async function SendGuiDataEvents(data) {
  try {
    if ((new Date().getTime() - sessionStorage.getItem("lastPublish")) > 300000) {  // 5mins
      sessionStorage.setItem("sessionStartTime", new Date().getTime());
    }

    if (!localStorage.getItem("randomNumber")) {
      const min = 10000;
      const max = 99999;
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      console.log("----- random number ----->", randomNumber);
      localStorage.setItem("randomNumber", randomNumber);
    }

    let sessionTime = localStorage.getItem("sessionTime") ? localStorage.getItem("sessionTime") : "";
    let sessionId = localStorage.getItem("sessionId") ? localStorage.getItem("sessionId") : "";
    if (sessionTime && sessionId && Date.now() - sessionTime > 300000) {
      const a = Date.now() + "" + localStorage.getItem("randomNumber");
      localStorage.setItem("sessionId", a);
      localStorage.setItem("user_type", "repeatedUser");
    }
    sessionTime = Date.now();
    if (sessionId === "") {
      sessionId = sessionTime + "" + localStorage.getItem("randomNumber");
      localStorage.setItem("sessionId", sessionId);
    }
    localStorage.setItem("sessionTime", sessionTime);

    let deviceId = localStorage.getItem("device_id") ? localStorage.getItem("device_id") : "";
    if (deviceId === "") {
      deviceId = Date.now() + "" + localStorage.getItem("randomNumber");
      console.log("----- device id set : --->" + deviceId);
      localStorage.setItem("device_id", deviceId);
    }

    const guiDataEvent = {
      operator_id: "mtn_uganda_dream_travel",
      domain: window.location.hostname,
      user_agent: navigator.userAgent,
      uid: localStorage.getItem("uid") ? localStorage.getItem("uid") : 'NA',
      msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : 'NA',
      device_type: "web",
      previouspage: "na",
      session_id: localStorage.getItem('sessionId'),
      channel: localStorage.getItem('channel') ? localStorage.getItem('channel') : "wap",
      trace: "",
      request_time: new Date().toISOString(),
      aon_time: getTotalDuration("local"),
      session_time_duration: getTotalDuration("session"),
      session_start_time: sessionStorage.getItem("sessionStartTime"),
      referrer_info: "na",
      request_time: new Date().toISOString(),
    };

    let tracer = localStorage.getItem("tracer") ? JSON.parse(localStorage.getItem("tracer")) : "";
    guiDataEvent["previouspage"] = tracer[tracer.length - 1];
    guiDataEvent["sub_event"] = data["page"] + "_" + data["event"];
    tracer = tracer + data["page"] + "_" + data["event"] + "|";
    localStorage.setItem("tracer", JSON.stringify(tracer));
    guiDataEvent["trace"] = tracer;

    let objData = { ...guiDataEvent, ...data };
    var jsonData_base64 = window.btoa(JSON.stringify(objData));

    let url = "https://serviceusagesv1.bngrenew.com/publish";
    const response = await fetch(url, {
      method: "POST",
      headers: { "guiTopic": "dreamtravel", "Content-Type": "text/plain" },
      body: jsonData_base64
    });

    if (response.ok) {
      console.log("the data is:", data);
      console.log("resp>>>", response);
      return true;
    } else {
      console.log("API request failed with status:", response.status);
      return false;
    }
  } catch (error) {
    console.log("Error sending event:", error);
    return false;
  }
}
