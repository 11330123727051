import React, { useContext } from 'react'
import { Context } from '../Context/Context'
import axios from 'axios'
import { baseUrl, headers } from '../config/config'
import { useNavigate } from 'react-router-dom'


function Modal() {
    const {modalData , handleModal, handlePageLoader, toggleModal} = useContext(Context)
    const navigate = useNavigate()


    const checkSubscriptionApi = async () => {
        // setLoader(true);
        handlePageLoader(true)
        const response = await axios({
            method: 'post',
            url: baseUrl+'checkSub',
            data: {uid: localStorage.getItem("uid")},
            headers: headers,
        })

        handlePageLoader(false)
        if ((response.data.subStatus).toUpperCase() === 'ACTIVE') {
            navigate('/home')
            // window.open('/home',"_self")
        }else if ((response.data.subStatus).toUpperCase() === 'NEW') {
            // navigate('/contest')
            window.open(localStorage.getItem("subUrl"),"_self")
        }

        else {
            // return  window.location.replace(configData.sub_url)
            window.open(localStorage.getItem("subUrl"),"_self")
        }
        // setLoader(false)

    }


  const closeModal = (value) => {
    if (modalData.btn.length > 1 && value === '1') {
    //   onHandleUnsub(uid)
        console.log("Yes btn clicked")
    } else if (modalData.btn.length > 1 && value === '2') {
      console.log("No btn clicked")
    } else {
      console.log("Okay btn clicked")
      console.log("modalData ------->",modalData)
      if(modalData.id === 'unsub'){
        // checkSub(uid)
        checkSubscriptionApi()
        console.log("unsub clicked")
      }else if(modalData.id === 'warning'){
        // window.open(redirect_url,"_self")
        toggleModal(false)

    }



    }
    handleModal(modalData,false);
  }


    return (
        <div className='absolute w-full max-w-[500px] h-full right-0 my-0 mx-auto left-0 bg-green_bg_pic z-60'>
            <div className='w-[20rem] absolute bg-white top-36 left-0 rounded-xl right-0 mx-auto border-4 border-gray-800 '>
            {/* <div className='text-right text-4xl pr-2' onClick={closeModal}>&times;</div> */}
            <div className='font-bold pt-6 text-center text-2xl'>{modalData.title}</div>
            <div className='pb-10 pt-3 text-center px-10'>
                <p className='text-xl'>{modalData.body}</p>
                <p className='mt-4 py-2 rounded font-bold text-white bg-[#607AF1]  bg-darkGreen border'  onClick={() =>closeModal("1")}>{modalData.btn[0]}</p>
                {modalData.btn.length > 1 && <p className='font-bold mt-2 py-2 rounded bg-[#607AF1]  text-white  bg-bgGreenIslam border'  onClick={() =>closeModal("2")}>{modalData.btn[1]}</p>}
            </div>
        </div>
        </div>

      )
}

export default Modal