import React,{useState,useEffect} from 'react';
import './style/welcomePage.css';
import LoaderComponent from './Loader';
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { baseUrl, headers } from '../config/config';
import { SendGuiDataEvents } from '../CommonScript';

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const WelcomePage = (props) => {
  useEffect(()=>{
    let guiEvents={};
    guiEvents["page"]="landingPage"
    guiEvents["event"]="page_view"
    SendGuiDataEvents(guiEvents)
  },[])
  
  const headerImg = require("../components/themeimg/assets/logo_new.png")
    const [bntLoader,setBtnLoader] = useState(false)
    // var baseUrl = props.baseUrl
    var apiData = props.apiData;
    localStorage.setItem("subUrl",apiData.sub_url)

    var loader = props.loader;
    var introPageData = props.introPageData;
    const navigate = useNavigate();

// getting uid
 console.log("api data is:::",apiData)
let query = useQuery();

const userId = query.get("userId")

console.log("userId ---> ", userId)

    const checkSubHandler = (uid) => {
      let guiEvents={};
          guiEvents["page"]="landingPage"
          guiEvents["event"]="checksub_request"
          SendGuiDataEvents(guiEvents)
        axios(
          {
            method: 'post',
            url: baseUrl + 'checkSub',
            data: {uid: uid},
            headers: headers,

          }).then((resp) => {
            console.log("api response is::",resp)
            let guiResEvents={};
              guiResEvents["page"]="landingPage"
              guiResEvents["event"]="checksub_response"
              guiResEvents["subStatus"]=resp.data.subStatus
              SendGuiDataEvents(guiResEvents);
              
              if (resp.data.status === 'failed') {
                // redirect to subURl
                console.log("Status is failed")
                window.open(apiData.sub_url,"_self");
              } 
              else if (resp.data.status === 'success') {
                if (resp.data.subStatus === 'new') {
                 window.open(apiData.sub_url, "_self")
                } else if (resp.data.subStatus === 'active') {
                    localStorage.setItem("msisdn", resp.data.msisdn)
                    localStorage.setItem("status", resp.data.status)
                    localStorage.setItem("subStatus", resp.data.subStatus)
                    navigate('/home')

                } else if (resp.data.subStatus === 'inactive') {
                     window.open(apiData.sub_url, "_self")
                }
              }
              else{
                 window.open(apiData.sub_url, "_self")
              }
            localStorage.setItem("subStatus", resp.data.subStatus)

          })
      }
   
      function onClickHandler() {
        setBtnLoader(true);
          if (localStorage.getItem('uid') && (localStorage.getItem('uid')!==undefined || localStorage.getItem('uid')!=='undefined' || localStorage.getItem('uid')!==null || localStorage.getItem('uid')!=="null") ) {
            console.log("test1")
            checkSubHandler(localStorage.getItem("uid"))
          } 
          else {
            console.log("redirect ---> app.js")
            let guiEvents={};
            guiEvents['page']='landingPage';
            guiEvents['event']='wap_redirection';
            guiEvents['sub_url']=apiData.sub_url;
            SendGuiDataEvents(guiEvents);
             window.open(apiData.sub_url, "_self")
            if(!apiData.sub_url && !localStorage.getItem('uid')){
             navigate("/error");
            }
            console.log("redirect")
          }
      }

    return (
        <>
            <div>
                <div>
                    <div className="container h-screen">
                      <div><img className="dreamTravelLogo"src={headerImg} alt="header"/></div>
                        {loader && <LoaderComponent/>
                        }
                
                        {!loader && <div className="theme-bg bg-cover  bg-white bg-no-repeat"  >
                            <img src={introPageData?.img_url} style={{height:"50vh"}} />
                            <div className="welcome-page-text has-text-centered absolute bottom-0 mb-2 px-1">
                                <div className="columns text-black">
                                    <h5 className="title text-black" style={{color:'black'}}>{introPageData?.title}</h5>
                                    <p>{introPageData?.desc}</p>
                                    <div className="button mx-auto is-warning is-fullwidth m-t-10 "style={{backgroundColor:"#FFCB00"}}  onClick={() => onClickHandler()} >{bntLoader && <div className='loader-btn'></div>}{!bntLoader && apiData?.button_text}</div>
                                    
                                </div>

                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default WelcomePage;